<template>
  <v-navigation-drawer v-model="drawer" app right color="grey lighten-4">
    <v-list dense class="grey lighten-4" v-if="docInfo && docInfo.layers[0]">
      <div v-for="(item, i) in docInfo.layers[0].boxes" :key="`C-${item.id}`">
        <v-list-item>
          <v-list-item-action>
            <v-icon>mdi-text-subject</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Consultation Question</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-row v-if="item.rationale" align="center">
          <v-col cols="4">
            <v-subheader v-if="item.pageNum">Page {{ item.pageNum }}</v-subheader>
            <v-subheader>Box {{i+1}}</v-subheader>
          </v-col>
          <v-col cols="8">
            <v-subheader v-if="item.rationale">{{ item.rationale }}</v-subheader>
          </v-col>
          <v-divider dark class="my-4" />
        </v-row>
        <v-list-item>
          <v-list-item-action>
            <v-icon>mdi-comment-text-multiple</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Comments</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <div v-if="comments">
          <div v-for="(comment, i ) in comments" :key="`B-${i}`">
          <v-list-item v-if="comment.box_id == item.id">
            <v-list-item-content>
              <v-row class="justify-end align-center">
                <v-col cols="12" sm="8" md="10">
                  <edit-comment :item="comment"/>
                   <v-subheader v-if="comment.agency_creator">By {{comment.agency_creator.FirstName}} {{comment.agency_creator.LastName}}</v-subheader>
                   <v-subheader v-else-if="comment.creator">By {{comment.creator.username}}</v-subheader>
                </v-col>
                <v-col cols="12" sm="4" md="2" class="pa-0">
                  <v-btn @click="deleteItem(comment.id)" icon color="red" x-small>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          </div>
        </div>
        <v-list-item>
         <add-comment :item="item"/>
        </v-list-item>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AddComment from './AddComment';

export default {
  components: {
    AddComment,
  },

  data() {
    return {
      drawer: true
    };
  },

  computed: {
    ...mapState("consultation", {
      docInfo: "consultDoc",
      consult: "consult",
      comments: "comments"
    })
  },

  created() {
    this.retrieveComments();
  },

  methods: {
    deleteItem(id) {
      if(confirm(this.$t("Are you sure you want to delete this item?"))) {
        this.deleteComment(id);
      }
    },

    ...mapActions("consultation", [
      "retrieveComments",
      "deleteComment"
    ])
  }
};
</script>