<template>
 <v-content class="doc-container">
    <div v-if="doc" class="text-center my-title" max-width="50">
      <h3>{{doc[0].req_code}}</h3>
      <div style="word-break: break-all;">{{doc[0].doc_file_name}}</div>
    </div>
      <div v-else-if="document" class="text-center my-title">
      <h3>{{document.request_id}}</h3>
      <div class="doc-name">{{document.name}}</div>
    </div>
  <v-container fluid fill-height justify-center>
    <v-tooltip bottom v-if="consult">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" dark fixed top left fab class="mt-12" @click="done" v-on="on">
          <v-icon>mdi-arrow-left-bold</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('Back to consultation list') }}</span>
    </v-tooltip>
    <pdf
      v-for="i in numPages"
      :key="`A-${i}`"
      :src="preview"
      :page="i"
      style="display: inline-block; width: 80%; margin-bottom: 10px;"
    ></pdf>
  </v-container>
   </v-content>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import pdf from "vue-pdf";

export default {
  props: ["preview", "numPages"],
  components: {
    pdf
  },
  data() {
    return {};
  },

  computed: {
    ...mapGetters('consultation', {
      doc: 'getConsultInfo',
    }),

    ...mapState('consultation', {
      consult: 'consult',
      document: 'consultDoc'
    })
  },

  methods: {
    done() {
      this.$emit("done");
    }
  }
};
</script>
<style lang="scss" scoped>
.doc-container {
  background-color: #CFD8DC;
}

.doc-name {
  word-break: break-all;
}

.my-title {
  background-color: #237076;
  color: #f9f9f9;
  position: absolute;
  font-size: 14px;
  top: 10px;
  right: 10px;
  z-index: 3;
  padding: 10px;
  border-radius: 5px;
  transition: 0.2s;
  max-width: 150px;
}
</style>