<template>
  <div>
    <v-text-field
      v-model="text"
      label="Add comment"
      @keyup.enter="send()"
      :rules="[max255chars]"
      counter
    >
      <v-icon slot="append" color="pink" @click="send()">mdi-plus</v-icon>
    </v-text-field>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: ["item"],

  data() {
    return {
      text: "",
      max255chars: v => v.length <= 255 || "Input too long!"
    };
  },

  methods: {
    send() {
      var value = this.text && this.text.trim();
      if (!value) {
        return;
      }
      this.text = "";
      const commentData = {
        box_id: this.item.id,
        comment: value
      };

      if(this.consult) {
        commentData.comment_from_agency = this.consult[0].agency_id;
        this.postComment(commentData);
      } else {
        this.postUserComment(commentData);
      }
    },

    ...mapActions("consultation", ["postComment", "postUserComment"])
  },

  computed: {
    ...mapState("consultation", {
      consult: "consult"
    })
  }
};
</script>