<template>
  <div>
    <nav-bar/>
    <PDFView :preview="preview" :numPages="numPages"/>
    <Comments/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import pdf from 'vue-pdf';
import NavBar from '../components/consultation/NavBar';
import PDFView from '../components/consultation/PDFView';
import Comments from '../components/consultation/Comments';
import axios from "axios";
export default {
  components: {
    NavBar,
    PDFView,
    Comments
  },

  data() {
    return {
      id: this.$route.params.id,
      preview: undefined,
      numPages: 1
    };
  },

  async created() {
    if (this.id) {
      this.preview = `${axios.defaults.baseURL}/print/${this.id}`;
      const loadingTask = pdf.createLoadingTask(this.preview);
      const src = loadingTask;
        await src.then(pdf => {
          this.numPages = pdf.numPages;
        });
      this.retrieveDocInfo(this.id);
    }
  },

  methods: {
    ...mapActions('consultation', ['retrieveDocInfo'])
  }
};
</script>
