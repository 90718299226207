<template>
  <v-app-bar app color="#237076" dark>
    <v-toolbar-title style="width: 300px" class="ml-0 pl-3">
      <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
      <span class="hidden-sm-and-down">Axcess1</span>
    </v-toolbar-title>
    <v-text-field
      flat
      solo-inverted
      hide-details
      prepend-inner-icon="mdi-magnify"
      :label="$t('search')"
      class="hidden-sm-and-down"
    ></v-text-field>
    <v-spacer></v-spacer>
     <span v-if="consult">Welcome {{ consult[0].contact_name }}</span>
    <v-btn icon>
      <v-icon>mdi-bell</v-icon>
    </v-btn>
    <!-- <v-btn icon large>
      <v-avatar size="32px" item>
        <v-img src="" alt="Axcess1"></v-img>
      </v-avatar>
    </v-btn> -->
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState('consultation', {
            consult: 'consult'
        })
    }
}
</script>